<template>
  <section>
    <table
      class="base-table"
      :style="styles"
    >
      <thead v-if="$slots.default">
      <tr>
        <th v-if="selectable" style="width: 52px">
          <el-checkbox
            :value="checkboxActive"
            :indeterminate="isIndeterminate"
            @change="selectAll"
          >
          </el-checkbox>
        </th>
        <slot/>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="(row, rowIndex) in data"
        :key="rowIndex"
        class="base-table__row"
        :class="{
           'base-table__row--selected': selectedRows.includes(row),
           'base-table__row--pointer' : rowClickable,
           'base-table__row--hover' : hoverActive,
        }"
      >
        <td v-if="selectable">
          <el-checkbox
            :id="rowIndex + ''"
            :value="rowSelected(row)"
            @change="selectRow(row)"
            @click="$event.stopPropagation()"
          >
          </el-checkbox>
        </td>
        <td
          v-for="columnName in columnsToDisplay"
          :key="columnName"
          @click="$emit('rowClick', row)"
        >
          <slot
            :name="columnName"
            :row="row"
            :rowIndex="rowIndex"
            :value="row[columnName]"
          >
            {{ row[columnName] }}
          </slot>
        </td>
      </tr>
      </tbody>
    </table>
    <div
      v-if="selectedRows.length && showSelectedRowsFooter"
      class="selected-rows__info"
    >
      <div class="selected-rows__left-btns">
        <span class="body-text color--black-purple">{{
            selectedRows.length
          }} {{ $t('adminTitles.of') }} {{ data.length }} {{ countText }}</span>
        <span class="body-text color--purple selected-rows__left-btn"
              @click.prevent="selectAllRows()"
        >
          {{ $t('adminTitles.selectAll') }}
        </span>
        <span class="body-text color--purple selected-rows__left-btn"
              @click.prevent="clearSelection()"
        >
          {{ $t('adminTitles.clear') }}
        </span>
      </div>
      <div class="selected-rows__right-btns">
        <div
          v-if="mergeBtn && selectedRows.length > 1"
          @click="$emit('mergeProfiles', selectedRows)"
          class="selected-rows__right-btn color--purple">
          <img alt="merge" src="/img/renova-dashboard/icons/merge.svg">
          <span>
          {{ mergeBtnText || $t('mergeProfiles.MergeProfiles') }}
        </span>
        </div>
        <div
          v-if="deactivateBtn"
          @click="$emit('deactivateSelected', selectedRows)"
          class="selected-rows__right-btn color--purple">
          <img alt="send" src="/img/renova-dashboard/icons/power--purple.svg">
          <span>
          {{ deactivateBtnText || $t('adminTitles.Deactivate') }}
        </span>
        </div>
        <div
          v-if="inviteBtn"
          @click="$emit('inviteSelected', selectedRows)"
          class="selected-rows__right-btn color--purple">
          <img alt="send" src="/img/renova-dashboard/icons/send.svg">
          <span>
          {{ inviteBtnText || $t('adminTitles.sendAnInvitation') }}
        </span>
        </div>
        <div
          v-if="deleteBtn"
          @click="$emit('deleteSelected', selectedRows)"
          class="selected-rows__right-btn color--red">
          <img alt="red trash" src="/img/renova-dashboard/icons/trash-solid-red.svg">
          <span class="body-text">
          {{ $t('buttons.delete') }}
        </span>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BaseTable',
  emits: ['selectionChanged', 'rowClick', 'deleteSelected', 'deactivateSelected', 'inviteSelected', 'mergeProfiles'],
  props: {
    data: {
      type: Array,
      required: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowClickable: {
      type: Boolean,
      required: false,
      default: false,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
    width: {
      type: String,
      required: false,
      default: '100%',
    },
    showSelectedRowsFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    deleteBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    hoverActive: {
      type: Boolean,
      required: false,
      default: true,
    },
    inviteBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    mergeBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    deactivateBtn: {
      type: Boolean,
      required: false,
      default: false,
    },
    countText: {
      type: String,
      required: false,
      default: null,
    },
    inviteBtnText: {
      type: String,
      required: false,
      default: null,
    },
    mergeBtnText: {
      type: String,
      required: false,
      default: null,
    },
    deactivateBtnText: {
      type: String,
      required: false,
      default: null,
    },
    initialSelectedRows: {
      type: Array,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      selectedRows: [],
      isIndeterminate: false,
    };
  },
  computed: {
    columnsToDisplay() {
      if (this.$slots.default && this.$slots.default.length) {
        return this.$slots.default
          .filter((slot) => slot.data?.attrs?.name)
          .map((slot) => slot.data?.attrs?.name);
      }

      return [];
    },
    checkboxActive() {
      return this.selectedRows.length === this.data.length;
    },
    styles() {
      return {
        '--table-width': this.width,
      };
    },
  },
  created() {
    if (this.initialSelectedRows) {
      this.selectedRows = this.initialSelectedRows;
    }
  },
  methods: {
    rowSelected(row) {
      return this.selectedRows.includes(row);
    },
    selectRow(row, event) {
      if (this.selectedRows.includes(row)) {
        this.selectedRows = this.selectedRows.filter((r) => r !== row);
      } else {
        this.selectedRows.push(row);
      }
      this.isIndeterminate = this.selectedRows.length > 0 && this.selectedRows.length !== this.data.length;
      this.$emit('selectionChanged', this.selectedRows);
    },
    selectAllRows() {
      this.selectedRows = this.data;
      this.isIndeterminate = false;
      this.$emit('selectionChanged', this.selectedRows);
    },
    selectAll() {
      if (this.isIndeterminate) {
        this.selectedRows = [];
        this.isIndeterminate = false;
      } else if (!this.isIndeterminate && this.selectedRows.length === this.data.length) {
        this.selectedRows = [];
      } else {
        this.selectedRows = this.data;
      }
      this.$emit('selectionChanged', this.selectedRows);
    },
    clearSelection() {
      this.selectedRows = [];
      this.isIndeterminate = false;
      this.$emit('selectionChanged', this.selectedRows);
    },
  },
  provide() {
    return {
      selectable: this.selectable,
    };
  },
};
</script>

<style lang="scss" scoped>
.base-table {
  border-collapse: collapse;
  width: var(--table-width);

  th {
    font-family: 'Inter', serif;
    font-weight: normal;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--solid-dark-purple);
    padding: 0 16px;
    height: 48px;
    text-align: left;
  }

  thead tr {
    border-bottom: 1px solid var(--solid-lavender-grey);
    border-top: 1px solid var(--solid-lavender-grey);
  }

  &__row {
    border-bottom: 1px solid var(--solid-lavender-grey);

    &--pointer {
      cursor: pointer;
    }
  }

  &__row.base-table__row--hover {
    &--selected, &:hover {
      background: var(--solid-lavender-ligth);
      .delete-btn{
        opacity: 1;
      }
    }
  }

  td {
    padding: 16px;
    text-align: left;
    color: var(--solid-black-purple);

    a {
      color: var(--solid-purple);
    }
  }
}

.base-table__row {
  font-size: 14px;
}

.selected-rows__info {
  font-size: 14px;
  display: flex;
  position: fixed;
  width: calc(100% - 240px);
  left: 240px;
  background-color: #FFFBEE;
  bottom: 0;
  z-index: 10;
  padding: 20px 24px 20px 24px;
  justify-content: space-between;
}

.selected-rows__left-btns {
  display: flex;
  align-items: center;
  gap: 24px;
}

.selected-rows__left-btn {
  cursor: pointer;
}

.selected-rows__right-btns {
  display: flex;
  align-items: center;
  gap: 24px;
}

.selected-rows__right-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}
</style>
