import axios from 'axios';
import {cloneDeep} from 'lodash';

const defaultFilters = {
  searchQuery: '',
  clientCompanyIds: [],
  programIds: [],
  policyEndDateFrom: null,
  policyEndDateTo: null,
  validityPeriod: null,
  activeStatuses: [],
  paymentStatus: [],
  paymentFormats: [],
};
const defaultState = {
  policies: {
    items: [],
    page: 0,
    totalCount: 50,
    loading: false,
  },
  policyDetails: {
    data: null,
    loading: false,
    insuredList: null,
    actions: {
      items: [{
        createdAt: '2024-08-14T13:19:32.279Z',
        action: 'Policy #VS-292983/85 paid',
      }],
      totalCount: 0,
      loading: false,
    },
    documents: [{
      id: '1',
      createdAt: '2024-08-14T13:19:32.279Z',
      fileName: '1'
    }]
  },
};
const state = {
  ...cloneDeep(defaultState),
  policiesFilters: {...defaultFilters},
};

const getters = {
  policiesList: () => state.policies.items,
  policiesTotalCount: () => state.policies.totalCount,
  policiesLoading: () => state.policies.loading,
  policiesFilters: () => state.policiesFilters,
  policyDetails: () => state.policyDetails.data,
  policyDetailsActions: () => state.policyDetails.actions.items,
  policyDetailsDocs: () => state.policyDetails.documents,
  policyDetailsInsuredList: () => state.policyDetails.insuredList,
  policyDetailsLoading: () => state.policyDetails.loading,
};
export const getPolicies = (page, limit, filters) => axios
  .post('/insurance/policy/list', {
    page,
    limit,
    ...filters,
  });
export const getPolicy = (policyId) => axios
  .post('/insurance/policy/detail', {policyId});
const actions = {
  fetchPolicies({
    commit,
    state
  }, {append = false} = {}) {
    commit('setPoliciesListLoading', true);
    getPolicies(state.policies.page, 50, state.policiesFilters)
      .then((response) => {
        if (append) {
          commit('appendPolicies', response.data);
        } else {
          commit('setPolicies', response.data);
        }
        commit('setPoliciesListLoading', false);
      })
      .catch((error) => {
        console.error('Error fetching policies:', error);
        commit('setPoliciesListLoading', false);
      });
  },

  updatePolicies({commit}, policies) {
    axios
      .post('/insurance/policy/save-bulk', {
        itemsSave: policies,
      })
      .then((response) => {
        commit('setUpdatedPolicies', policies);
      })
      .catch((error) => {
        console.error('Error updating policies:', error);
      });
  },

  deletePolicies({
    commit,
    dispatch
  }, insurancePolicyIds) {
    axios
      .post('/insurance/policy/save-bulk', {
        insurancePolicyIds,
        insurancePolicyDelete: true,
      })
      .then((response) => {
        dispatch('fetchPolicies');
      })
      .catch((error) => {
        console.error('Error deleting policies:', error);
      });
  },
  deactivatePolicies({ commit, dispatch }, data) {
    axios
      .post('/insurance/policy/save-bulk', {
        insurancePolicyIds: data.insurancePolicyIds,
        deactivate: {
          deactivationDate: data.deactivationDate
        },
      })
      .then((response) => {
        dispatch('fetchPolicies');
      })
      .catch((error) => {
        console.error('Error deleting policies:', error);
      });
  },
  activatePolicy({
    commit,
    dispatch
  }, insurancePolicyIds) {
    return axios.post('/insurance/policy/save-bulk', {
      insurancePolicyIds,
      activate: {},
    })
      .then((resp) => resp.data);
  },
  fetchPolicyDetails({
    commit,
    dispatch
  }, policyId) {
    commit('setDetailsLoading', true);
    // dispatch('fetchPolicyActions', policyId);
    getPolicy(policyId).then((response) => {
      commit('setPolicyDetails', response.data);
      commit('setDetailsLoading', false);
    })
      .catch((error) => {
        console.error('Error fetching policy details:', error);
        commit('setDetailsLoading', false);
      });
  },
  // fetchPolicyActions({ commit }, policyId) {
  //   commit('setActionsLoading', true);
  //   axios
  //     .post('/insurance/policy/audit-log/list', {
  //       policyId,
  //       page: 0,
  //       limit: 1000,
  //     })
  //     .then((response) => {
  //       // commit('setPolicyActions', response.data);
  //       commit('setActionsLoading', false);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching policy details:', error);
  //       commit('setActionsLoading', false);
  //     });
  // },
  fetchPolicyInsured({commit}, groupInsurancePolicyIds) {
    axios
      .post('/insurance/policy/list', {
        groupInsurancePolicyIds,
        page: 0,
        limit: 1000,
      })
      .then((response) => {
        commit('setPolicyInsuredList', response.data.items);
      })
      .catch((error) => {
        console.error('Error fetching policy details:', error);
      });
  },
  savePolicy({
    commit,
    dispatch
  }, body) {
    axios
      .post('/insurance/policy/save', {
        ...body,
      })
      .then((response) => {
        dispatch('fetchPolicies');
        if (body.policyId) {
          dispatch('fetchPolicyDetails', body.policyId);
        }
      })
      .catch((error) => {
        console.error('Error fetching policy details:', error);
      });
  },
  exportPolicies({
    commit,
    dispatch
  }, data) {
    axios
      .post('/insurance/policy/export', {
        ...data,
      })
      .then((resp) => {
        const fileName = data.type === 'POLICY' ? 'Policies' : 'Payments';

        const link = document.createElement('a');
        link.href = URL.createObjectURL(
          new Blob([resp.data], {
            type: 'text/csv',
          })
        );
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error fetching policy details:', error);
      });
  },
  addPolicyFile({
    commit,
    dispatch
  }, body) {
    axios
      .post('/insurance/policy/save', {
        ...body,
      })
      .then((response) => {
        dispatch('fetchPolicyDetails', body.policyId);
      })
      .catch((error) => {
        console.error('Error add file:', error);
      });
  },

  deletePolicyFile({
    commit,
    dispatch
  }, body) {
    axios
      .post('/insurance/policy/save', {
        ...body,
      })
      .then((response) => {
        dispatch('fetchPolicyDetails', body.policyId);
      })
      .catch((error) => {
        console.error('Error delete file:', error);
      });
  },
  updatePoliciesFilters({
    commit,
    dispatch
  }, filters) {
    commit('setPoliciesFilters', filters);
    dispatch('fetchPolicies');
  },
  clearPolicyStore({commit}) {
    commit('clearPolicyStore');
  },
};

const mutations = {
  setPolicies(state, policies) {
    state.policies.items = policies.items;
    state.policies.totalCount = policies.totalCount;
  },

  appendPolicies(state, policies) {
    state.policies.items = [...state.policies.items, ...policies.items];
    state.policies.page += 1;
  },

  setPoliciesListLoading(state, loading) {
    state.policies.loading = loading;
  },

  setPolicyDetails(state, details) {
    state.policyDetails.data = details;
  },
  setPolicyActions(state, actions) {
    state.policyDetails.actions = actions;
  },

  setDetailsLoading(state, loading) {
    state.policyDetails.loading = loading;
  },
  setActionsLoading(state, loading) {
    state.policyDetails.actions.loading = loading;
  },

  setUpdatedPolicies(state, updatedPolicies) {
    state.policies.items = state.policies.items.map((policy) => {
      const updatedPolicy = updatedPolicies.find((val) => val.policyId === policy.policyId);
      if (updatedPolicy) {
        return {
          ...policy,
          ...updatedPolicy,
          stage: updatedPolicy.stageSave,
        };
      }
      return policy;
    });
  },

  setPoliciesFilters(state, policiesFilters) {
    state.policies.page = 0;
    state.policiesFilters = {...state.policiesFilters, ...policiesFilters};
  },
  setPolicyInsuredList(state, insuredList) {
    state.policyDetails.insuredList = insuredList;
  },

  clearPolicyStore(state) {
    state.policies = defaultState.policies;
    state.policyDetails = defaultState.policyDetails;
    state.policiesFilters = defaultFilters;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
