/* eslint-disable no-shadow,no-param-reassign */
import axios from 'axios';
import {PermissionsTypes} from '@/utils/permissions';
import {
  USER_REQUEST, USER_ERROR, USER_SUCCESS, USER_SAVE_USEDESK_TOKEN, USER_SAVE,
} from '../actions/user';
import { AUTH_LOGOUT } from '../actions/auth';

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  DOCTOR: 'DOCTOR',
  INSURANCE_OPERATOR: 'OPERATOR',
  AGENT: 'AGENT',
};
export const getUserRole = (user) => {
  if (user.domains.some((domain) => domain.roles && domain.roles.role === USER_ROLES.ADMIN)) {
    return USER_ROLES.ADMIN;
  }
  if (user.domains.some((domain) => domain.roles && domain.roles.role === USER_ROLES.DOCTOR)) {
    return USER_ROLES.DOCTOR;
  }
  if (user.domains.some((domain) => domain.roles && domain.roles.role === USER_ROLES.INSURANCE_OPERATOR)) {
    return USER_ROLES.INSURANCE_OPERATOR;
  }
  return USER_ROLES.ADMIN;
};

export const getUserDoctorId = (user) => {
  const doctorDomain = user.domains.find((domain) => domain.roles && domain.roles.doctor);
  return doctorDomain ? doctorDomain.roles.doctor.doctorId : null;
};
const state = {
  status: 'loading',
  user: {},
  permissions: [],
  selectedInsuranceProvider: null,
  selectedInsuranceProviderDetails: null,
  insuranceProviders: [],
};

const getters = {
  user: (state) => state.user,
  isUserLoading: (state) => state.status === 'loading',
  permissions: (state) => state.user.permissions?.allow,
  isUserSuperAdmin: (state) => state.user.permissions?.isSuperAdmin,
  userRole: (state) => getUserRole(state.user),
  userDoctorId: (state) => getUserDoctorId(state.user),
  getUserId: (state) => state.user.id,
  isProfileLoaded: (state) => !!state.user.phone || !!state.user.email,
  selectedInsuranceProviderDetails: (state) => state.selectedInsuranceProviderDetails,
  selectedInsuranceProvider: (state) => state.selectedInsuranceProvider,
  insuranceProviders: (state) => state.insuranceProviders,
};

const actions = {
  [USER_REQUEST]({ commit, dispatch }) {
    commit(USER_REQUEST);

    return axios.get('/user/info')
      .then((response) => {
        commit(USER_SUCCESS, response.data);

        return response;
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          dispatch(AUTH_LOGOUT);
        }
        commit(USER_ERROR);

        return Promise.reject(err);
      });
  },
  [USER_SAVE_USEDESK_TOKEN]({ commit, dispatch }, token) {
    return axios.post('/user/usedesk/token/save', token)
      .then((resp) => resp)
      .catch(() => Promise.reject());
  },
  [USER_SAVE]({ commit, dispatch }, user) {
    return axios.put('/user/info', user)
      .then((resp) => {
        dispatch(USER_REQUEST);
      })
      .catch(() => Promise.reject());
  },
};

const mutations = {
  [USER_REQUEST]: (state) => {
    // state.user = {};
    state.status = 'loading';
  },
  [USER_SUCCESS]: (state, user) => {
    state.user = { ...user };
    const insuranceProviders = user.domains?.filter((domain) => domain.type === 'INSURANCE_ADMIN_WORKSPACE').map((domain) => ({
      ...domain.insuranceCompany,
      domain,
    }));
    const selectedProvider = localStorage.getItem(`${user.id}_insuranceProvider`) || (insuranceProviders.length ? insuranceProviders[0]?.id : null);
    state.insuranceProviders = insuranceProviders;
    state.selectedInsuranceProvider = selectedProvider;
    state.selectedInsuranceProviderDetails = insuranceProviders.find((provider) => provider.id === selectedProvider);
    state.user.permissions = state.selectedInsuranceProviderDetails?.domain?.roles?.permissions;
    state.status = 'success';
  },
  [USER_ERROR]: (state) => {
    state.status = 'error';
  },
  [AUTH_LOGOUT]: (state) => {
    state.user = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
