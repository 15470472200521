import axios from 'axios';

const defaultFilters = {
  insurancePlanTypes: [],
  renewalStages: [],
  transactionStatuses: [],
  agentUserIds: []
};
const state = {
  renewals: {
    items: [],
    loading: false,
  },
  filteredRenewalsList: [],
  filters: {...defaultFilters},
};

const getters = {
  renewalList: () => state.renewals.items,
  filteredRenewalsList: () => state.filteredRenewalsList,
  renewalsLoading: () => state.renewals.loading,
  renewalsFilters: () => state.filters,
};

const actions = {
  fetchRenewals({commit, state}) {
    commit('setRenewalsListLoading', true);
    axios.post('/insurance/policy/renewal/list', {
      page: 0,
      limit: 1000,
      ...state.filters,
    }).then((response) => {
      commit('setRenewals', response.data);
      commit('updateFilteredRenewals');
      commit('setRenewalsListLoading', false);
    }).catch((error) => {
      console.error('Error fetching renewals:', error);
      commit('setRenewalsListLoading', false);
    });
  },

  updateRenewal({commit, dispatch}, renewal) {
    axios.post(`/insurance/${renewal.packageId ? 'package' : 'policy'}/save`, {
      ...renewal
    })
      .then((response) => {
        dispatch('fetchRenewals');
      })
      .catch((error) => {
        console.error('Error updating renewals:', error);
      });
  },
  renewPolicy({commit, dispatch}, renewal) {
    axios.post('/insurance/policy/renew', {
      ...renewal
    })
      .then((response) => {
        dispatch('fetchRenewals');
      })
      .catch((error) => {
        console.error('Error updating renewals:', error);
      });
  },
  exportRenewals({commit, dispatch}, renewals) {
    axios.post('/insurance/renewals/download', {
      renewals
    })
      .then((resp) => {
        const fileName = 'Renewals';

        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([resp.data], {
          type: 'text/csv',
        }));
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error fetching renewal details:', error);
      });
  },
  updateFilters({commit, dispatch}, filters) {
    commit('setFilters', filters);
    dispatch('fetchRenewals');
    // commit('updateFilteredRenewals');
  },

};

const mutations = {
  setRenewals(state, renewals) {
    state.renewals.items = renewals.items;
  },

  setRenewalsListLoading(state, loading) {
    state.renewals.loading = loading;
  },

  setFilters(state, filters) {
    state.filters = {...state.filters, ...filters};
  },

  clearAllFilters(state) {
    state.filters = {...defaultFilters};
  },
  updateFilteredRenewals() {
    state.filteredRenewalsList = state.renewals.items;
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
