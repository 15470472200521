<template>
  <div>
    <AdminPageTitle>
      {{ $t("adminTitles.programs") }}
    </AdminPageTitle>
    <div class="programs-toolbar">
      <div class="programs-filters">
        <InputSearch :value="searchKey" @searchUpdate="searchKey = $event"></InputSearch>
        <BaseDropdown
          :selectedOptions="filters.clientCompanyIds ? filters.clientCompanyIds : []"
          :options="clientCompanies"
          :multiple="true"
          :width="'200px'"
          :placeholder="$t('adminTitles.company')"
          @changeOptions="updateFilterValue('clientCompanyIds', $event)"
        />
        <BaseDropdown
          :selectedOptions="filters.insuranceCompanyIds ? filters.insuranceCompanyIds : []"
          :options="filteredInsuranceCompanies"
          :multiple="true"
          :width="'200px'"
          :placeholder="$t('adminTitles.insuranceCompanyShort')"
          @changeOptions="updateFilterValue('insuranceCompanyIds', $event)"
        />
        <BaseDropdown
          :selectedOptions="statusFilterValue"
          :options="activeList"
          :multiple="false"
          :width="'200px'"
          :placeholder="$t('adminTitles.status')"
          @changeOptions="updateActiveFilter"
        />
        <BaseDropdown
          :selected-options="filters.insuranceTypes"
          :multiple="true"
          :width="'200px'"
          :clearable="true"
          :placeholder="$t('programTypes.InsuranceType')"
          :options="INSURANCE_TYPES_OPTIONS"
          @changeOptions="updateFilterValue('insuranceTypes', $event)"
        />
        <BaseDropdown
          :selected-options="filters.insurancePlanTypes"
          :multiple="true"
          :width="'200px'"
          :clearable="true"
          :placeholder="$t('adminTitles.PlanType')"
          :options="PLAN_TYPES_OPTIONS"
          @changeOptions="updateFilterValue('insurancePlanTypes', $event)"
        />
      </div>
      <BaseSimpleDropdown
        v-if="checkOptionalUserPermissions([PermissionsTypes.PLANS_CREATE])"
        class="hr-main__dropdown"
        :position="'bottom-left'"
        :offset-top="45"
        :options="[
          { text: $t('programTypes.NewIndividualPlan'), id: 'newIndividualPlan' },
          { text: $t('programTypes.NewGroupPlan'), id: 'newCorporatePlan' },
        ]"
        @selectOption="activeModal = $event"
      >
        <template #optionButton>
          <BaseButton>
            {{ $t("buttons.newProgram") }}
          </BaseButton>
        </template>
      </BaseSimpleDropdown>
    </div>

    <div class="programs-table">
      <el-skeleton v-if="isDataLoading && !programsList.items.length" :rows="20" />

      <BaseTable
        v-else-if="programsTableData"
        :headers="programsTableHeaders"
        :data="programsTableData"
        :row-clickable="false"
      >
        <BaseTableColumn name="Name">{{ $t("adminTitles.name") }}</BaseTableColumn>
        <BaseTableColumn name="Company">{{ $t("adminTitles.company") }}</BaseTableColumn>
        <BaseTableColumn name="Provider"
          >{{ $t("adminTitles.insuranceCompanyShort") }}
        </BaseTableColumn>
        <BaseTableColumn name="Services">{{ $t("adminTitles.services") }}</BaseTableColumn>
        <BaseTableColumn name="People">{{ $t("adminTitles.people") }}</BaseTableColumn>
        <BaseTableColumn name="Status" style="width: 150px"
          >{{ $t("adminTitles.status") }}
        </BaseTableColumn>
        <BaseTableColumn name="DeleteBtn" style="width: 50px"></BaseTableColumn>
        <template #Name="{ row }">
          <p class="body-text color--purple cursor__pointer" @click="openProgram(row)">
            {{ row.Name }}
          </p>
        </template>
        <template #Company="{ row }">
          <div class="company-cell" @click="editableCompany = row.ProgramCompany">
            <span v-html="row.Company" />
          </div>
        </template>
        <template #Status="{ row }">
          <div class="table__cell-status" @click="$event.stopPropagation()">
            <div
              :class="row.IsAvailable ? 'status-green' : 'status-orange'"
              class="row-status body-text color--black-purple"
            >
              {{ row.IsAvailable ? $t("adminTitles.Active") : $t("adminTitles.inactive") }}
            </div>
            <InputSwitcher
              v-if="checkOptionalUserPermissions([PermissionsTypes.PLANS_UPDATE])"
              class="table__cell-status-switcher"
              :checked="row.IsAvailable"
              @change="togglePlanStatus(row)"
            >
            </InputSwitcher>
          </div>
        </template>
        <template #Services="{ row }">
          <span v-if="row.offerGenerationStatus !== 'IN_PROCESS'">{{ row.Services }}</span>
          <BasePreloader
            v-if="row.offerGenerationStatus === 'IN_PROCESS'"
            :size="'m'"
            :color="'purple'"
          >
          </BasePreloader>
        </template>
        <template #DeleteBtn="{ row }">
          <div class="delete-btn" v-if="checkOptionalUserPermissions([PermissionsTypes.PLANS_DELETE])">
            <img
              src="/img/renova-dashboard/icons/trash.svg"
              alt="delete"
              @click="planForDelete = row"
            />
          </div>
        </template>
      </BaseTable>
      <NoResultsPlaceholder v-else />
    </div>
    <ModalCreateNewCompany
      v-if="editableCompany"
      :companyEdit="editableCompany"
      @close="editableCompany = false"
      @saved="
        initData();
        editableCompany = null;
      "
      @deleted="
        initData();
        editableCompany = null;
      "
    />
    <ModalCreateNewCorporatePlan
      v-if="activeModal === 'newCorporatePlan'"
      @close="activeModal = null"
    />
    <ModalCreateNewIndividualPlan
      v-if="activeModal === 'newIndividualPlan'"
      @close="activeModal = null"
    />
    <BaseDialog
      v-if="planForDelete"
      :width="'444px'"
      :text-align="'left'"
      :footer-align="'flex-end'"
      :title="$t('adminTitles.DeletePlanTitle')"
      :text="$t('adminTitles.DeletePlanText', { name: planForDelete.Name })"
      :confirm-button-text="$t('adminTitles.Delete')"
      :cancel-button-text="$t('adminTitles.goBack')"
      :confirm-loading="deleteBtnLoading"
      @close="
        planForDelete = null;
        deleteBtnLoading = false;
      "
      @confirm="deletePlan(planForDelete)"
    />
  </div>
</template>

<script>
import AdminPageTitle from '@/views/RenovaDashboard/CommonParts/AdminPageTitle';
import { mapGetters } from 'vuex';
import {
  PROGRAM_SAVE,
  PROGRAMS_LIST_FILTERS_CHANGE,
  PROGRAMS_LIST_FILTERS_CLEAR,
  PROGRAMS_LIST_REQUEST,
} from '@/store/actions/programs';
import { CLIENT_COMPANIES_REQUEST } from '@/store/actions/client-companies';
import BaseButton from '@/views/RenovaDashboard/components/ui-kit/Buttons/BaseButton';
import BaseTable from '@/views/RenovaDashboard/components/ui-kit/BaseTable/BaseTable';
import NoResultsPlaceholder from '@/views/RenovaDashboard/components/ui-kit/NoResultsPlaceholder';
import { adminEventBus } from '@/layouts/RenovaDashboardLayout';
import BaseDropdown from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseDropdown';
import InputSearch from '@/views/RenovaDashboard/components/ui-kit/Inputs/InputSearch';
import BasePreloader from '@/views/RenovaDashboard/components/ui-kit/Buttons/BasePreloader';
import BaseSimpleDropdown from '@/views/RenovaDashboard/components/ui-kit/Dropdowns/BaseSimpleDropdown';
import ModalCreateNewIndividualPlan from '@/views/RenovaDashboard/ProgramsParts/components/modals/ModalCreateNewIndividualPlan';
import ModalCreateNewCompany from '@/views/RenovaDashboard/CompaniesParts/components/modals/ModalCreateNewCompany';
import InputSwitcher from '@/views/RenovaDashboard/components/ui-kit/Inputs/InputSwitcher';
import BaseDialog from '@/views/RenovaDashboard/components/ui-kit/BaseDialog';
import ModalCreateNewCorporatePlan from '@/views/RenovaDashboard/ProgramsParts/components/modals/ModalCreateNewCorporatePlan';
import {PlanTypeOptions} from '@/views/RenovaDashboard/ProgramsParts/components/constants';
import {UserPermissions} from '@/utils/permissions';
import BaseTableColumn from '../components/ui-kit/BaseTable/BaseTableColumn';

export default {
  name: 'TheProgramsView',
  components: {
    ModalCreateNewCorporatePlan,
    BaseDialog,
    InputSwitcher,
    ModalCreateNewCompany,
    ModalCreateNewIndividualPlan,
    BaseSimpleDropdown,
    BasePreloader,
    InputSearch,
    BaseDropdown,
    NoResultsPlaceholder,
    // ModalCreateNewProgram,
    BaseButton,
    BaseTable,
    AdminPageTitle,
    BaseTableColumn,
  },
  mixins: [PlanTypeOptions, UserPermissions],
  data() {
    return {
      filters: {},
      searchKey: '',
      activeFilter: null,
      activeModal: null,
      activeList: [
        {
          id: 'active',
          name: this.$t('adminTitles.activeProgram'),
        },
        {
          id: 'inactive',
          name: this.$t('adminTitles.inactiveProgram'),
        },
      ],
      showAddServiceModal: true,
      editableCompany: null,
      planForDelete: null,
      deleteBtnLoading: false,
    };
  },
  beforeDestroy() {
    this.$store.dispatch(PROGRAMS_LIST_FILTERS_CLEAR);
  },
  watch: {
    programsListFilters: {
      handler() {
        this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
      },
      deep: true,
    },
    searchKey() {
      this.$store.commit('PROGRAMS_LIST_SEARCH_QUERY_CHANGE', this.searchKey);
    },
    activeFilter() {
      this.$store.commit('PROGRAMS_LIST_STATUS_FILTER_CHANGE', this.activeFilter);
    },
  },
  computed: {
    ...mapGetters([
      'programsList',
      'programsListFilters',
      'programsStatusFilter',
      'programsSearchQuery',
      'ticketsFiltersData',
      'activeInsuranceCompanies',
      'dictionaryLoading',
      'clientCompanies',
      'clientCompaniesList',
      'filteredInsuranceCompaniesByCompanyIds',
    ]),
    /**
     * Check if data necessary for table is loading
     * @returns {boolean}
     */
    isDataLoading() {
      return (
        this.programsList.loading || this.dictionaryLoading || this.clientCompaniesList.loading
      );
    },
    /**
     * Filtered insurance companies by selected client company ids
     * @returns {*}
     */
    filteredInsuranceCompanies() {
      // If no client company selected, return all insurance companies
      if (!this.filters.clientCompanyIds.length) {
        return this.activeInsuranceCompanies;
      }
      return this.filteredInsuranceCompaniesByCompanyIds(this.filters.clientCompanyIds);
    },
    statusFilter() {
      return this.activeFilter !== ''
        ? this.programsList.items.filter((item) => item.isAvailable === this.activeFilter)
        : this.programsList.items;
    },
    statusFilterValue() {
      if (this.activeFilter === '') {
        return [];
      }
      if (!this.activeFilter) {
        return ['inactive'];
      }
      return ['active'];
    },
    filterPrograms() {
      return this.statusFilter.filter((item) => item.name.toLowerCase().includes(this.searchKey.toLowerCase()));
    },
    /**
     * Prepare Headers for table columns
     * @returns {{Status: VueI18n.TranslateResult, Services: VueI18n.TranslateResult, Company: VueI18n.TranslateResult, People: VueI18n.TranslateResult, Name: VueI18n.TranslateResult, Provider: VueI18n.TranslateResult}}
     */
    programsTableHeaders() {
      return {
        Name: this.$t('adminTitles.name'),
        Company: this.$t('adminTitles.company'),
        Provider: this.$t('adminTitles.insuranceCompanyShort'),
        Services: this.$t('adminTitles.services'),
        People: this.$t('adminTitles.people'),
        Status: this.$t('adminTitles.status'),
      };
    },
    /**
     * Prepare data for programs table
     * @returns {{Status: string, Services: *, Company: string|*|string, People: *, Name: *, Provider: *}[]}
     */
    programsTableData() {
      return this.filterPrograms.map((item) => ({
        Id: item.packageId,
        Name: item.name,
        Company: this.getCompanyLabel(item),
        ProgramCompany: this.findProgramCompany(item.clientCompany?.id),
        Provider: item.insuranceCompany?.name,
        Services: item.offerCount,
        offerGenerationStatus: item.offerGenerationStatus,
        People: item.policyCount,
        IsAvailable: item.isAvailable,
        program: item,
      }));
    },
  },
  created() {
    this.initData();
    this.filters = JSON.parse(JSON.stringify(this.programsListFilters));
    this.searchKey = this.programsSearchQuery;
    this.activeFilter = this.programsStatusFilter;
    this.setDefaultFilters();
  },
  mounted() {
    adminEventBus.$emit('breadcrumbs', [
      {
        title: this.$t('adminTitles.programs'),
        link: '/admin/programs',
      },
    ]);
  },
  methods: {
    togglePlanStatus(row) {
      if (row.IsAvailable) {
        this.setNewProgramStatus(row.program, !row.IsAvailable);
      } else {
        const peopleError = !row.People ? this.$t('adminTitles.AddInsuredIndividuals') : null;
        const nameError = !row.Name ? this.$t('adminTitles.EnterThePlanName') : null;
        const providerError = !row.Provider
          ? this.$t('adminTitles.ChooseAnInsuranceProvider')
          : null;
        const errorsTexts = [peopleError, nameError, providerError]
          .filter((val) => !!val)
          .map((error) => `<li>${error}</li>`)
          .join('');
        if (peopleError || nameError || providerError) {
          const title = this.$t('adminTitles.UnableToActivatePlan');
          const description = this.$t('adminTitles.ItIsNotPossibleToActivatePlan', { errorsTexts });
          this.$notify.warning({
            message: `<strong>${title}</strong><br><div class="error-description">${description}</div>`,
            dangerouslyUseHTMLString: true,
            duration: 10000,
          });
        } else {
          this.setNewProgramStatus(row.program, !row.IsAvailable);
        }
      }
    },
    setNewProgramStatus(program, isAvailable) {
      this.$store
        .dispatch(PROGRAM_SAVE, {
          ...program,
          isAvailable,
        })
        .then(() => {
          this.$notify.success({
            message: `${this.$t('adminTitles.success')}`,
          });
          this.initData();
        });
    },
    setDefaultFilters() {
      if (this.$route.query.clientCompany) {
        this.updateFilterValue('clientCompanyIds', [this.$route.query.clientCompany]);
        this.activeFilter = true;
      }
    },
    updateFilterValue(field, value) {
      this.filters[field] = value;
      this.changeFilter();
    },
    changeFilter() {
      this.filters.page = 1;
      this.$store.dispatch(PROGRAMS_LIST_FILTERS_CHANGE, this.filters);
    },
    updateActiveFilter(value) {
      this.activeFilter = !value.length ? '' : value[0] === 'active';
    },
    /**
     * Handle programs table row click
     * @param row - item of programsList.data
     */
    openProgram(row) {
      this.$router.push({
        path: `/admin/programs/${row.Id}`,
        query: {
          deactivationRedirect: this.$route.query.redirectTo,
        },
      });
    },
    initData() {
      this.$store.dispatch(PROGRAMS_LIST_REQUEST);
      this.$store.dispatch(CLIENT_COMPANIES_REQUEST);
    },
    findProgramCompany(id) {
      return this.clientCompaniesList.items.find((company) => company.clientCompanyId === id);
    },
    getCompanyLabel(item) {
      if (item.clientCompany) {
        const company = this.findProgramCompany(item.clientCompany.id);
        if (company && company.logo) {
          const companyLogo = `<div class="company-logo"><img src="${company?.logo.link}" alt="company-logo"/></div>`;
          return `<div class="company-with-logo">${companyLogo} ${item.clientCompany.name}</div>`;
        }
        return item.clientCompany.name;
      }

      return '';
    },
    deletePlan(program) {
      this.deleteBtnLoading = true;
      this.$store
        .dispatch(PROGRAM_SAVE, {
          packageId: program.Id,
          packageDelete: true,
        })
        .then(() => {
          this.planForDelete = null;
          this.deleteBtnLoading = false;
          this.$notify.success({
            message: this.$t('adminTitles.PlanSuccessfullyDeleted', {
              name: program.Name,
            }),
          });
          this.initData();
        })
        .catch((err) => {
          this.deleteBtnLoading = false;
          this.$notify.error({
            message: `${this.$t('adminTitles.someError')}`,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.programs-toolbar {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  position: sticky;
  top: -32px;
  background-color: white;
  z-index: 10;
}

.programs-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

::v-deep .company-with-logo {
  display: flex;
  align-items: center;

  .company-logo {
    width: 24px;
    height: 24px;
    border-radius: 190px;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
      height: 100%;
      object-fit: contain;
      width: 24px;
    }
  }
}

.company-cell {
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: var(--solid-purple);
  }
}

.table__cell-status {
  display: flex;
  align-items: center;
  width: 168px;
  min-width: 168px;
  gap: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.table__cell-status-switcher {
  margin-left: auto;
  cursor: pointer;
}
</style>
<style lang="scss">
.error-description {
  ul {
    padding: 0 0 0 16px;
    margin: 12px 0 0;
  }
}
.delete-btn {
  opacity: 0;
  transition: 0.3s;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  cursor: pointer;
}
</style>
